
export enum GlobalDnsStatus {
  Failed = "Failed",
  Skipped = "Skipped",
  Unknown = "Unknown",
  Processing = "Processing",
  Deployed = "Deployed"
}

export class SpokeRequestSimpleDto {

  mappingId?: string;
  spokeId?: string;
  projectId?: string;
  projectName?: string;
  tenantName?: string;
  subscriptionId?: string; // TODO SNP make me guid
  environment?: string;
  status?: string;
  createdBy?: string; // TODO SNP make me guid
  deploymentId?: number;
  deploymentUrl?: string;

  globalDnsStatus: GlobalDnsStatus = GlobalDnsStatus.Unknown;
  globalDnsError?: string;

  constructor(partial?: Partial<SpokeRequestSimpleDto>) {
    Object.assign(this, partial);
  }

}
