import { PrimaryHubDataDto } from "./primary-hub-data.model";

export class SecondaryHubDataDto extends PrimaryHubDataDto
{
  deployGlobalDns: boolean = false;

  constructor(partial?: Partial<SecondaryHubDataDto>) {
    super(partial);
  }
}
