import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { GlobalDnsStatus, SpokeRequestSimpleDto } from '../../api/spoke-request-simple.model';
import { SpokeRequestsService } from '../../api/services/spoke-requests.service';
import { LayoutService } from '../../../../../shared/services/layout.service';
import { SpokeRequestsDataComponent } from '../spoke-requests-data/spoke-requests-data.component';
import { SpokeRequestCreateComponent } from '../spoke-request-create/spoke-request-create.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from '../../../../../shared/services/notification.service';


export const GlobalDnsStatusClass: { [key in GlobalDnsStatus]: string } = {
  [GlobalDnsStatus.Failed]: 'text-red-500',
  [GlobalDnsStatus.Skipped]: 'text-yellow-200',
  [GlobalDnsStatus.Unknown]: 'text-200',
  [GlobalDnsStatus.Processing]: 'text-blue-500',
  [GlobalDnsStatus.Deployed]: 'text-green-500'
};

@Component({
  selector: 'app-spoke-requests-list',
  templateUrl: './spoke-requests-list.component.html',
  providers: [DialogService, ConfirmationService],
})
export class SpokeRequestsListComponent {

  filters: SpokeRequestsFilters;
  filterProjects: string[] = [];
  filterCreators: string[] = [];
  filterStatuses: string[] = [];

  spokeRequests: SpokeRequestSimpleDto[] = [];
  spokeRequestsFiltered: SpokeRequestSimpleDto[] = [];

  spokeRequestDetailsDialog?: DynamicDialogRef;
  spokeRequestCreateNewDialog?: DynamicDialogRef;

  getStatusClass (status: GlobalDnsStatus): string {
    return GlobalDnsStatusClass[status]; 
  }
  constructor(
    private spokeRequestService: SpokeRequestsService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private layout: LayoutService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.filters = new SpokeRequestsFilters();
  }

  async ngOnInit() {
    await this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();
    this.spokeRequestService.spokeRequestsGetList()
      .subscribe({
        next: (response: SpokeRequestSimpleDto[]) => {
          this.spokeRequests = response;
          this.refreshFilterLists(response);
          this.filterList();
          this.layout.stopLoading();
        },
        error: (error) => {
          this.layout.stopLoading();
        }
      });
  }

  refreshFilterLists(tableData: SpokeRequestSimpleDto[]) {
    this.filterProjects = [...new Set(tableData.map(q => q.projectId).filter((q): q is string => q !== undefined || q !== null))];
    this.filterCreators = [...new Set(tableData.map(q => q.createdBy).filter((q): q is string => q !== undefined || q !== null))];
    this.filterStatuses = [...new Set(tableData.map(q => q.status).filter((q): q is string => q !== undefined || q !== null))];
  }

  filterList() {
    this.spokeRequestsFiltered = this.spokeRequests.filter(q => {
      return (!this.filters.project || q.projectId === this.filters.project) &&
              (!this.filters.creator || q.createdBy === this.filters.creator) &&
              (!this.filters.status || q.status === this.filters.status);
    });
  }

  filtersClear() {
    this.filters.project = undefined;
    this.filters.creator = undefined;
    this.filters.status = undefined;

    this.filterList();
  }

  openSpokeRequestDetailsDialog(mappingId: string) {
    this.spokeRequestDetailsDialog = this.dialogService
      .open(SpokeRequestsDataComponent, {
        data: { mappingId },
        header: `Spoke Request (${mappingId})`,
        width: '800px'
      });

  //dialog is read only we don't need to refresh the list
  //  this.spokeRequestDetailsDialog.onClose
  //    .subscribe(() => {
  //        this.refreshList();
  //    });
  }

  globalDnsDialog(item: SpokeRequestSimpleDto) {
    var status = `<br/>Current GlobalDNS status: <b>${item.globalDnsStatus}</b><br/>`;
    var error = item.globalDnsError ? `<p class='text-red-700'><small>${(item.globalDnsError ?? '').replaceAll(';', '<br/>')}</small></p><br/>` : '';
    this.confirmationService.confirm({
      message: `${status}<br/><b>Do you want to deploy GlobalDNS?</b><br/><br/> ${error}`,
      header: 'GlobaDNS Confirmation',
      //icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: 'Deploy GlobalDNS',
      rejectLabel: 'Cancel',

      accept: () => {
        if (!item.mappingId) {
          return;
        }
        this.spokeRequestService.spokeRequestDeployGlobalDns(item.mappingId)
          .subscribe(() => {
            this.notificationService.info('Started', 'GlobalDNS');
          });
      },
      reject: () => {
      }
    });
  }

  openSpokeRequestCreateDialog() {
    this.spokeRequestCreateNewDialog = this.dialogService
      .open(SpokeRequestCreateComponent, {
        header: 'Spoke Request - Create New',
        width: '800px'
      });

    this.spokeRequestCreateNewDialog.onClose
      .subscribe(() => {
          this.refreshList();
      });
  }

  redirectToApproval(mappingId: string) {
    this.router.navigateByUrl(`/nps/project-spokes/requests/approvals/${mappingId}`)
  }
}

class SpokeRequestsFilters {
  project?: string;
  creator?: string;
  status?: string;
}
