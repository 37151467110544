
<h5>Secondary Hub Category</h5>

<!-- TODO SNP check shSubcategoryForm -->
@if (!isUpdate && (shCategoryForm || (shMetadataForm && !formBlocked))) {
  <label for="createCategorySw" class="block text-900 font-medium mb-2">Do you need to create a new category?</label>
  <p-inputSwitch id="createCategorySw"[(ngModel)]="createCategory" />


  <form class="mt-4" [formGroup]="shCategoryForm!">
    @if (createCategory) {
      <h6>Category</h6>

        <div class="field">
          <label for="categoryId" class="block text-900 font-medium mb-2">New Secondary Hub Category Id</label>
          <input id="categoryId" formControlName="categoryId" type="text" pInputText placeholder="eg. 9x for DEV, 0x for PROD" class="w-full">
        </div>

        <div class="field">
          <label for="categoryDescription" class="block text-900 font-medium mb-2">New Secondary Hub Category description</label>
          <input id="categoryDescription" formControlName="categoryDescription" type="text" pInputText placeholder="...some text..." class="w-full">
        </div>

    }
    @else {
      <div class="field">
        <label for="categoryIdText" class="block text-900 font-medium mb-2">Existing Secondary Hub Category Id</label>
        <p-dropdown
          id="categoryIdText"
          formControlName="categoryIdText"
          [options]="(secondaryHubCategoriesOptions | async) ?? []"
          optionLabel="name"
          optionValue="value"
          placeholder="Select existing Category"
          appendTo="body"
          styleClass="w-full"
          inputStyleClass="w-full"/>
      </div>
    }
  </form>

  <h6>Subcategory</h6>
  <form class="mt-4" [formGroup]="shSubcategoryForm!">
    <div class="field">
      <label for="subcategoryId" class="block text-900 font-medium mb-2">New Secondary Hub Subcategory Id</label>
      <input id="subcategoryId" formControlName="subcategoryId" type="text" pInputText placeholder="000" class="w-full">
    </div>

    <div class="field">
      <label for="subcategoryDescription" class="block text-900 font-medium mb-2">New Secondary Hub Subcategory description</label>
      <input id="subcategoryDescription" formControlName="subcategoryDescription" type="text" pInputText placeholder="...some text..." class="w-full">
    </div>
  </form>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

<h5>Secondary Hub Description</h5>

@if (shMetadataForm || (shMetadataForm && !formBlocked)) {
<form [formGroup]="shMetadataForm" class="mt-4">
  <div>
    <!-- TODO snp make them visible but readonly -->
    @if(!isUpdate) {
      <div class="field">
        <label for="secondaryHubId" class="block text-900 font-medium mb-2">Secondary Hub Id</label>
        <span class="font-italic block pb-1">Optional, will generate new one if empty in format: SH-[categoryId]-[subcategoryId]</span>
        <input id="secondaryHubId" formControlName="secondaryHubId" type="text" pInputText placeholder="SH-99-00" class="w-full">
      </div>

      <div class="field">
        <label for="projectId" class="block text-900 font-medium mb-2">Project Id</label>
        <p-dropdown
          id="projectId"
          formControlName="projectId"
          [options]="(projectOptions | async) ?? []"
          placeholder="Select a Project Profile"
          appendTo="body"
          styleClass="w-full"
          inputStyleClass="w-full"/>
        <div *ngIf="shMetadataForm.controls['projectId'].dirty && !shMetadataForm.controls['projectId'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(shMetadataForm, 'projectId')">{{ error }}</small>
        </div>
      </div>
    }
    @else {
      <span>Identifiers are not enabled for modification</span>
    }

  </div>
</form>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

<h5>Secondary Hub Data</h5>

@if(shDataForm || (shDataForm && !formBlocked)) {

<form [formGroup]="shDataForm" class="mt-4">
  <div>

    <!-- TODO snp make them visible but readonly -->
    @if (!isUpdate) {
    <div class="field">
      <label for="tenantId" class="block text-900 font-medium mb-2">TenantId</label>
      <input id="tenantId" formControlName="tenantId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
      <div *ngIf="shDataForm.controls['tenantId'].dirty && !shDataForm.controls['tenantId'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'tenantId')">{{ error }}</small>
      </div>
    </div>
    }

    <div class="field">
      <label for="firewallIP" class="block text-900 font-medium mb-2">Firewall IP</label>
      <input id="firewallIP" formControlName="firewallIP" type="text" pInputText placeholder="0.0.0.0" class="w-full">
      <div *ngIf="shDataForm.controls['firewallIP'].dirty && !shDataForm.controls['firewallIP'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'firewallIP')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
      <label for="subscriptionId" class="block text-900 font-medium mb-2">Azure Subscription ID</label>
      <input id="subscriptionId" formControlName="subscriptionId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
      <div *ngIf="shDataForm.controls['subscriptionId'].dirty && !shDataForm.controls['subscriptionId'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'subscriptionId')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
      <label for="resourceGroupName" class="block text-900 font-medium mb-2">Azure Resource Group Name</label>
      <input id="resourceGroupName" formControlName="resourceGroupName" type="text" pInputText placeholder="eg. rg-azuf-001" class="w-full">
      <div *ngIf="shDataForm.controls['resourceGroupName'].dirty && !shDataForm.controls['resourceGroupName'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'resourceGroupName')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
      <label for="virtualNetworkName" class="block text-900 font-medium mb-2">Azure Virtual Network Name</label>
      <input id="virtualNetworkName" formControlName="virtualNetworkName" type="text" pInputText placeholder="eg. vnet-azuf-001" class="w-full">
      <div *ngIf="shDataForm.controls['virtualNetworkName'].dirty && !shDataForm.controls['virtualNetworkName'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'virtualNetworkName')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
      <label for="virtualNetworkCIDR" class="block text-900 font-medium mb-2">Azure Virtual Network CIDR</label>
      <input id="virtualNetworkCIDR" formControlName="virtualNetworkCIDR" type="text" pInputText placeholder="0.0.0.0/24" class="w-full">
      <div *ngIf="shDataForm.controls['virtualNetworkCIDR'].dirty && !shDataForm.controls['virtualNetworkCIDR'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'virtualNetworkCIDR')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
      <label for="location" class="block text-900 font-medium mb-2">Azure Deployment Location</label>
      <p-dropdown id="location" formControlName="location" [options]="(azureRegionOptions | async) ?? []" class="w-full" placeholder="Select target Region" appendTo="body" styleClass="w-full" inputStyleClass="w-full" />
      <div *ngIf="shDataForm.controls['location'].dirty && !shDataForm.controls['location'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'location')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
      <label for="environment" class="block text-900 font-medium mb-2">Environment</label>
      <p-dropdown id="environment" formControlName="environment" [options]="(deploymentStageOptions | async) ?? []" placeholder="Select target Environment" appendTo="body" styleClass="w-full" inputStyleClass="w-full" />
      <div *ngIf="shDataForm.controls['environment'].dirty && !shDataForm.controls['environment'].valid" class="block font-sm mb-3 text-red-500">
        <small *ngFor="let error of getErrorsFromControl(shDataForm, 'environment')">{{ error }}</small>
      </div>
    </div>
    <div class="field">
        <label for="deployGlobalDns" class="block text-900 font-medium mb-2">Deploy GlobalDNS</label>
        <p-inputSwitch id="deployGlobalDns" formControlName="deployGlobalDns" />
    </div>

    <h5>Features</h5>

    <label for="features" class="block text-900 font-medium mb-2">Spoke Features JSON Object</label>
    <textarea id="features"
              style="width: 100%; overflow: scroll; min-height: 200px;"
              formControlName="features"
              placeholder="{}">
    </textarea>

  </div>
</form>

}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

@if (!formBlocked) {
  <div class="text-center mt-5">
    <p-button label="Cancel" icon="pi pi-times" class="m-3" (click)="onCancelClick()"></p-button>
    <p-button label="Save" icon="pi pi-check" class="m-3" [disabled]="!(shMetadataForm?.valid) || !(shDataForm?.valid)" (onClick)="onSaveClick()"></p-button>
  </div>
}
@else {
  <div class="flex-row mt-5">
    <p class="flex-1">Processing...</p>
    <p-progressBar class="flex-1" mode="indeterminate" [style]="{ height: '6px' }" inputStyleClass="w-full" />
  </div>
}
